<template>
  <div :class="['container', 'col-start-center']">
    <nav-bar header-title="服务评价" :show-back="true" :header-back="appBack" />
    <div v-if="form.newFlag === 1" class="block-container col-start-center">
      <div class="block">
        <div v-if="form.notice" class="tip f24 row-start-center">
          <!-- 您已经评价，可修改后提交新的评价~ -->
          {{ form.notice }}
        </div>
        <h2 class="f30 fw6">您对此次接待服务是否满意？</h2>
        <div class="row-around-center">
          <div :class="[
            'choose-item',
            'col-start-center',
            'f24',
            form.contentStatus === item.value ? 'active' : '',
          ]" @click="choose(item.value)" v-for="item in chooseItems" :key="item.value">
            <img :src="
              form.contentStatus === item.value ? item.activePic : item.pic
            " alt="" class="" />
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="block flex-auto">
        <van-field v-model="form.content" rows="15" autosize type="textarea" :placeholder="pla" maxlength="100" />
      </div>
      <div class="buttons row-between-center">
        <!-- <div v-if="from !== 'new-group'" class="cl-button flex-none f32 fw6 grey row-center-center" @click="appBack">关闭</div> -->
        <div :class="[
          'cl-button',
          'flex-auto',
          'f32',
          'fw6',
          'row-center-center',
          form.contentStatus === '' ? 'disabled' : '',
        ]" @click="submit">
          {{ hasEvaluate ? '修改提交' : from === 'new-group' ? '提交并领取优惠' : '提交' }}
        </div>
      </div>
    </div>
    <div v-else class="empty col-center-center">
      <img src="https://img.chaolu.com.cn/ACT/user-evaluate/empty.png" alt="" />
      <p>{{ form.notice }}</p>
    </div>
  </div>
</template>

<script>
import userMixin from "@/mixin/userMixin";
import { newAppBack, initBack, appOpenWeb } from "@/lib/appMethod";
import navBar from "@/components/nav-bar-202210/index";
import { getParam } from "../lib/utils";
import wx from "weixin-js-sdk";
export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      chooseItems: [
        {
          name: "不满意",
          value: "0",
          pic: "https://img.chaolu.com.cn/ACT/common-icon/angry-1.png",
          activePic: "https://img.chaolu.com.cn/ACT/common-icon/angry-2.png",
        },
        {
          name: "满意",
          value: "1",
          pic: "https://img.chaolu.com.cn/ACT/common-icon/smile-1.png",
          activePic: "https://img.chaolu.com.cn/ACT/common-icon/smile-2.png",
        },
      ],
      coachId: "", // teacherId与user.mixin重名 所以换一个
      currentVenueId: "",
      form: {
        contentStatus: "",
        content: "",
        indoorId: "", // 入场id
        notice: "",
      },
      from: '', // 来源
      pla: "请对此次接待服务进行评价~",
      hasEvaluate: false,
    };
  },
  async created() {
    this.coachId = getParam().teacherId || "";
    this.currentVenueId = getParam().venueId || "";
    this.from = getParam().from;

    if (!this.from) {
      if (this.appTypeStr === "mini") {
        wx.miniProgram.reLaunch({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/new-people&teacherId=${this.coachId}&venueId=${this.currentVenueId}`,
        });
      } else {
        this.$router.replace(
          `/superdeer-activity/new-people?teacherId=${this.coachId}&venueId=${this.currentVenueId}`
        );
      }
      return
    }

    initBack();
    await this.$getAllInfo();
    this.initData();
  },
  methods: {
    appBack() {
      if (this.appTypeStr === "mini") {
        wx.miniProgram.switchTab({
          url: `/pages/home/index`,
        });
      } else {
        newAppBack();
      }
    },
    initData() {
      let req = "";
      switch (this.from) {
        case "new-group":
          req = "/userscheduleserviceevaluation/app/scanTeacherQrcode";
          break;
        default:
          req = "/indoorEvaluation/app/scanTeacherQrcode";
          break;
      }

      this.$axios
        .post(this.baseURLApp + req, {
          teacherId: this.coachId,
          userId: this.userId,
          venueId: this.currentVenueId,
        })
        .then((res) => {
          this.form = res.data;
          this.hasEvaluate = res.data.contentStatus !== "" ? true : false;
        });
    },
    choose(i) {
      this.form.contentStatus = i;
    },
    submit() {
      if (this.form.contentStatus === "") return;

      let req = "";
      switch (this.from) {
        case "new-group":
          req = "/userscheduleserviceevaluation/app/submitComments";
          break;
        default:
          req = "/indoorEvaluation/app/submitComments";
          break;
      }

      this.$axios.post(this.baseURLApp + req, this.form).then((res) => {
        if (this.from === 'new-group') {
          const r = res.data

          let w = '感谢您对本次服务进行评价'
          if (this.appTypeStr === 'ios') w = encodeURIComponent(w)
          let str = `reason=${w}`

          Object.keys(r).forEach((key) => {
            const val = this.appTypeStr === 'ios' ? encodeURIComponent(r[key]) : r[key]
            str += `&${key}=${val}`
          })

          appOpenWeb('', `${window.location.origin}/#/superdeer-activity/marketing?${str}`)
        } else {
          this.$toast("感谢您的评价~");
          setTimeout(() => {
            this.appBack();
          }, 3000);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  box-sizing: border-box;
  height: 100vh;

  * {
    box-sizing: border-box;
  }

  background-color: #f5f5f5;

  .block-container {
    height: 100vh;
    padding: 32px 32px calc(144px + constant(safe-area-inset-bottom));
    padding: 32px 32px calc(144px + env(safe-area-inset-bottom));
    width: 100%;
  }

  .block {
    width: 100%;
    padding: 24px 28px;
    margin: 0 0 24px;
    background-color: #fff;

    .tip {
      width: 100%;
      background: #fff0e5;
      border-radius: 4px;
      color: #ff6e00;
      margin: 0 0 42px;
      padding: 12px 20px;
    }

    h2 {
      margin: 0 0 46px;
    }
  }

  .choose-item {
    color: #6c727a;

    img {
      width: 64px;
      margin: 0 0 16px;
    }

    &.active {
      color: #242831;
    }
  }

  .van-cell {
    padding: 0;
  }

  .buttons {
    width: 100%;
    padding: 24px 32px calc(24px + constant(safe-area-inset-bottom));
    padding: 24px 32px calc(24px + env(safe-area-inset-bottom));
    position: fixed;
    left: 0;
    bottom: 0;
    background: #ffffff;
    box-shadow: inset 0px 1px 0px 0px rgba(238, 238, 238, 1);
  }

  .cl-button {
    width: 328px;
    height: 92px;
    border-radius: 8px;
    background: #ffde00;
    border: 2px solid #ffde00;

    &.grey {
      background: #fff;
      border: 2px solid #dddddd;
    }

    &.disabled {
      background: #ddd;
      border: 2px solid #ddd;
      color: #fff;
    }
  }

  .success {
    padding: 72px 24px 28px;

    h3 {
      margin: 0 0 72px;
    }

    p {
      margin: 72px 0 90px;
    }

    img {
      width: 576px;
    }
  }

  .empty {
    width: 100%;
    height: 100vh;

    img {
      width: 322px;
    }

    p {
      margin: 24px 0 0;
      width: 500px;
      text-align: center;
    }
  }
}

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont";
  /* Project id 1802820 */
  src: url("//at.alicdn.com/t/c/font_1802820_2s9oc9fthr2.woff2?t=1664521615621") format("woff2"),
    url("//at.alicdn.com/t/c/font_1802820_2s9oc9fthr2.woff?t=1664521615621") format("woff"),
    url("//at.alicdn.com/t/c/font_1802820_2s9oc9fthr2.ttf?t=1664521615621") format("truetype");
}
</style>
<style>
.van-field__control {
  font-family: "iconfont";
}

.van-field__control::-webkit-input-placeholder {
  color: #9aa1a9;
}
</style>
