<template>
  <div class="container">
    <nav-bar header-title="固定费用闸机" :show-back="true" :header-back="appBack" />
    <div class="list">
      <div class="card" v-for="item in gateMachines" :key="item.doorId">
        <p class="p1 f30 fw6">{{ item.ticketName }}</p>
        <p class="p2 f26">票价：{{ item.ticketAmount }}元</p>
        <p class="p3 f26">时效：单次进出馆</p>
        <p class="p4 f24">说明：{{ item.ticketDepict }}</p>
        <div class="row-end-center">
          <div class="button row-center-center f28" @click="$router.push(`/swimming/payee?doorId=${item.doorId}&teacherId=${teacherId}&venueId=${item.storeId}&name=${item.ticketName}&money=${item.ticketAmount}`)">
            <van-icon name="qr" />二维码收费
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      gateMachines: []
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo(['teacherId'])
    initBack()

    // this.teacherId = '581115746236997632'
    this.getGatemachine()
  },
  methods: {
    appBack: newAppBack,
    getGatemachine() {
      this.$axios.post(`${this.baseURLApp}/swimSingleFee/querySwimTicketList`, {
        teacherId: this.teacherId
      }).then(res => {
        this.gateMachines = res.data
      })
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }

  background: #F9FAFB;
  padding: calc(100px + constant(safe-area-inset-top)) 24px 24px;
  padding: calc(100px + env(safe-area-inset-top)) 24px 24px;

  .list {
    .card {
      width: 100%;
      padding: 32px 32px 40px;
      background-color: #fff;
      border-radius: 20px;
      margin: 24px 0 0;

      color: #242831;

      .p2 {
        margin: 24px 0 0;
      }

      .p3 {
        margin: 16px 0 0;
      }

      .p4 {
        margin: 28px 0 0;
        color: #6C727A;
      }

      .button {
        width: 244px;
        height: 88px;
        background: #3C454E;
        border-radius: 8px;
        margin: 20px 0 0;
        color: #fff;

        .van-icon {
          font-size: 36px;
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
</style>