<template>
  <div class="container">
    <!-- <nav-bar header-title="固定费用闸机" backColor="#fff" :header-back="appBack" /> -->
    <div class="banner col-start-center">
      <img class="ticket-bg"
        src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/swimming/ticket1.png" alt="" />
      <div :class="['ticket', isAni ? 'ani' : '']">
        <p class="ticketname f48 fw6">{{ params.ticketName }}</p>
        <p class="price fw6 f76 BebasNeueBold"><span class="f36 fw6">¥</span>{{ params.ticketAmount }}</p>
        <p class="limit f28">出票时效：单次进出馆</p>
        <p class="time f28">出票时间：{{ params.scanTime }}</p>
        <div class="line"></div>
        <div class="user row-start-center">
          <img class="avator" :src="params.headImg" alt="">
          <p class="username f32 fw6">{{ `${params.nickName}（${params.mobilePhone}）` }}</p>
        </div>
        <div class="mask"></div>
      </div>
      <img class="ticket-bg"
        src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/swimming/ticket2.png" alt="" />
    </div>
    <div class="success row-center-center f36 fw6">
      <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/swimming/right-icon.png" alt="">
      扫码出票成功
    </div>
    <div class="desc">
      <p class="title f32 fw6">门票说明：</p>
      1. <span class="modify">扫码成功</span>后请将此页面给教练查看后即可进入进行游泳，门票费用将于您出馆后统一自助订单合并结算<br /><br />
      2. 若您本次进馆时间区间内有<span class="modify">购买游泳课程</span>，则不会收取您的游泳门票费用，可免费进入泳池
    </div>
    <div class="footer row-center-center">
      <div class="button f32 fw5 row-center-center" @click="appBack">已给教练查看，关闭</div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar/nav-bar'
export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {

      userInfo: { // 用户信息
        headImg: '',
        ticketName: '',
        ticketAmount: '',
        nickName: '',
        scanTime: ''
      },
      isAni: false,
      params: {},
    }
  },
  computed: {},
  async mounted() {
    await this.$getAllInfo()
    initBack()

    this.params = getParam()
    Object.keys(this.params).forEach((key) => {
      this.params[key] = decodeURIComponent(this.params[key])
    })
    console.log(this.params);
    this.isAni = true
  },
  methods: {
    appBack: newAppBack,

  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 0 0 300px;
  * { box-sizing: border-box; }
  .banner {
    width: 100%;
    height: 360px;
    // background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/swimming/ticket-bg.png);
    // background-size: 100% 100%;
    position: relative;

    .ticket-bg {
      width: 100%;

      &:nth-child(1) {
        position: relative;
        z-index: 2;
      }
    }

    .ticket {
      width: 662px;
      height: 520px;
      background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/swimming/ticket.png);
      background-size: 100% 100%;
      padding: 56px 32px 0px;
      position: absolute;
      left: 44px;
      top: -328px;
      .mask {
        width: 100%;
        height: 26px;
        background: linear-gradient(180deg, #2D303E 0%, rgba(45, 48, 62, 0) 100%);
        filter: blur(0px);
        position: absolute;
        top: 0;
        left: 0;
      }
      &.ani {
        animation: ticket 1s ease-out 1s;
        animation-fill-mode: forwards;
      }
      .price {
        margin: 24px 0 0;
      }
      .limit {
        margin: 16px 0 0;
      }
      .line {
        width: 610px;
        border-bottom: 4px dashed #242831;
        margin: 32px 0 0;
      }
      .time {
        margin: 16px 0 0;
      }
      .user {
        margin: 46px 0 0;
        .avator {
          width: 80px;
          border-radius: 50%;
          margin: 0 16px 0 0;
        }
      }
    }
  }

  .success {
    margin: 408px 0 76px;
    color: #242831;

    img {
      width: 50px;
      margin: 0 16px 0 0;
    }
  }

  .desc {
    padding: 0 32px;
    color: #3C454E;

    .title {

      color: #242831;
      margin: 0 0 32px;
    }

    .modify {
      box-shadow: 0px -10px 0px 0px #FFDE00 inset;
    }

  }

  .footer {
    width: 750px;
    height: 138px;
    background: #FFFFFF;
    position: fixed;
    left: 0;
    bottom: 0;

    .button {
      width: 686px;
      height: 96px;
      background: #242831;
      border-radius: 8px;
      color: #fff
    }
  }

  @keyframes ticket {
    0% {
      top: -328px;
    }
    100% {
      top: 92px;
      filter: drop-shadow(0 0 8px #FFF5BA);
    }
  }
}
</style>